import React from "react";
import { connect } from "react-redux";
import MarketCategoryButton from "./MarketCategoryButton";
import {
  startGetProducts,
  startGetCauses,
  startAddProducts,
  startAddCauses,
} from "../../redux/Market/market.actions";
import ProductCard from "./ProductCard";
import CauseCard from "./CauseCard";
import BePartOf from "./BePartOf";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const MarketBody = ({
  startGetProductsData,
  startGetCausesData,
  productsArray,
  causesArray,
  productsNextPageUrl,
  causesNextPageUrl,
  selectedCategoryData,
  selectedCategoryName,
  goodsOrCauses,
  dispatchStartAddProducts,
  dispatchStartAddCauses,
  closeSearchDiv,
}) => {
  const [state, setState] = React.useState(window.location.search.includes("category=products") ? "goods" : "causes");
  const { t, i18n } = useTranslation();
  const [causeLoadMore, setCauseLoadMore] = React.useState(false);
  const [productLoadMore, setProductLoadMore] = React.useState(false);
  const history = useHistory();


  React.useEffect(() => {
    console.log("state : ", state);
    if (state === "causes") {
      // startGetProductsData();
      startGetCausesData();
      setState("causes");
      //setState('products');
    } else if (state === "goods") {
      startGetProductsData();
      setState("goods");
    }
    getLoadMore(state);
  }, [state]);


  const getLoadMore = (_state) => {
    if (causesNextPageUrl && _state === "causes") {
      setCauseLoadMore(true);
    } else if (productsNextPageUrl && _state === "goods") {
      setProductLoadMore(true);
    } else {
      setCauseLoadMore(false);
      setProductLoadMore(false);
    }
  };

  React.useEffect(() => {
    getLoadMore(state);
  }, [causesNextPageUrl, productsNextPageUrl]);


  const loadMore = () => {
    if (productsNextPageUrl && ["start", "goods"].includes(state)) {
      dispatchStartAddProducts(productsNextPageUrl);
    } else if (
      causesNextPageUrl &&
      ["start", "causes"].includes(state)
    ) {
      dispatchStartAddCauses(causesNextPageUrl);
    }
  };

  const PRODUCTS_DATA = productsArray;
  const CAUSES_DATA = causesArray;

  const getGoodsOrCauses = () => {
    if (selectedCategoryData && selectedCategoryName) {
      if (
        goodsOrCauses === "goods" &&
        selectedCategoryData &&
        selectedCategoryData.length &&
        selectedCategoryData.indexOf("error") === -1
      ) {
        // setState('products')
        return selectedCategoryData?.map((data, index) => {
          return <ProductCard key={index} item={data} />;
        });
      } else if (
        goodsOrCauses === "causes" &&
        selectedCategoryData &&
        selectedCategoryData.length &&
        selectedCategoryData.indexOf("error") === -1
      ) {

        return selectedCategoryData?.map((data, index) => {
          return <CauseCard key={index} item={data} />;
        });
      }
    }
  };
  return (
    <div
      className="bg-bodyBg p-8 rounded-tl-bodyxl rounded-tr-bodyxl"
      onClick={closeSearchDiv}
    >
      <div className="flex flex-row w-full items-center justify-center gap-x-12 mb-8">
        <MarketCategoryButton
          onClick={() => {
            setState("causes");
          }}
          type="causes"
          active={
            (state === "causes")
          }
        />
        <MarketCategoryButton
          onClick={() => {
            setState("goods");
          }}
          type="products"
          active={
            (state === "goods")
          }
        />
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  mx-auto gap-4">
        {goodsOrCauses
          ? getGoodsOrCauses()
          : state === "goods"
          ? PRODUCTS_DATA?.length > 0
            ? PRODUCTS_DATA.map((data, index) => {
                return <ProductCard key={index} item={data} />;
              })
            : null
          : state === "causes"
          ? CAUSES_DATA?.length > 0
            ? CAUSES_DATA.map((data, index) => {
                return <CauseCard key={index} item={data} />;
              })
            : null
          : null}
      </div>
      {(productLoadMore || causeLoadMore) && (
        <div
          className="w-full text-center text-primary underline py-2 btn-effects font-LatoRegular mt-10"
          onClick={() => {
            loadMore();
          }}
        >
          {t('profile.load_more')}
        </div>
      )}
      <BePartOf />
    </div>
  );
};

const mapState = ({ market }) => ({
  causesArray: market.causesArray,
  productsArray: market.productsArray,
  causesNextPageUrl: market.causesNextPageUrl,
  causesPrevPageUrl: market.causesPrevPageUrl,
  productsNextPageUrl: market.productsNextPageUrl,
  productsPrevPageUrl: market.productsNextPageUrl,
  selectedCategoryData: market.selectedCategoryData,
});

const mapDispatchToProps = (dispatch) => ({
  startGetCausesData: () => dispatch(startGetCauses()),
  startGetProductsData: () => dispatch(startGetProducts()),
  dispatchStartAddCauses: (pageurl) =>
    dispatch(startAddCauses(pageurl)),
  dispatchStartAddProducts: (pageurl) =>
    dispatch(startAddProducts(pageurl)),
});

export default connect(mapState, mapDispatchToProps)(MarketBody);
