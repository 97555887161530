import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logo from '../assets/shared/logo/logo.png';
import { useTranslation } from 'react-i18next';
import colors from '../constants/colors';
import Cookies from './Cookies';
import {
  AiOutlineInstagram,
  AiFillLinkedin,
  AiFillFacebook,
} from 'react-icons/ai';
import { IoLocationOutline, IoLogoTiktok } from 'react-icons/io5';

export const Footer = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <div className={`w-full bg-primary p-6 md:p-16 ${props.newLanding ? 'mt-0' : 'mt-24' } ${props.newLanding ? 'bg-cyan-950' : 'bg-primary' } `}>
      <div className="flex flex-wrap bg-white py-6 md:py-12 px-4 md:px-10 font-LatoRegular rounded-kkxl">
        <div className="flex flex-col w-full sm:w-1/2 md:w-1/4 text-sm text-greyText justify-around">
          <img src={logo} alt="logo" className="w-44 mb-4" />
          <div className="mb-8">{t('footer.we_reward')}</div>
          <div className="flex flex-row justify-between w-7/12 btn-effects">
            <a
              href="https://www.instagram.com/magikkarma/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiOutlineInstagram color={colors.theme} size={24} />
            </a>
            <a
              href="https://www.linkedin.com/company/magikk-co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillLinkedin color={colors.theme} size={24} />
            </a>

            <a
              href="https://m.facebook.com/magikkarma/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillFacebook color={colors.theme} size={24} />
            </a>

            <a
              href="https://vm.tiktok.com/ZMLefs8Tf/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoLogoTiktok color={colors.theme} size={24} />
            </a>
          </div>
        </div>
        <div className="flex flex-col w-full sm:w-1/2 md:w-1/4 text-sm text-greyText">
          <div className="font-VolksDemiBold text-lg text-black mb-8">
            {t('footer.quick_links')}
          </div>
          <div
            onClick={() => {
              history.push('/market?category=products');
            }}
            className="mb-1 text-hover"
          >
            {t('login.market')}
          </div>
          <div
            onClick={() => {
              history.push('/market');
            }}
            className="mb-1 text-hover"
          >
            {t('login.i_am_ngo')}
          </div>
          <div
            onClick={() => {
              history.push('/privacy-policy');
            }}
            className="mb-1 text-hover"
          >
            {t('login.privacy_policy')}
          </div>
          <div
            onClick={() => {
              history.push('/term-of-use');
            }}
            className="mb-1 text-hover"
          >
            {t('login.useterm')}
          </div>
        </div>
        <div className="flex flex-col w-full sm:w-1/2 md:w-1/4 text-sm text-greyText">
          <div className="font-VolksDemiBold text-lg text-black mb-8">
            {t('footer.lets_talk')}
          </div>
          <div className="mb-2">{'falacomigo@magikk.com'}</div>
          <div>{'+55 21 99628 6404'}</div>
        </div>
        <div className="flex flex-col w-full sm:w-1/2 md:w-1/4 text-sm text-greyText">
          <div className="font-VolksDemiBold text-lg text-black mb-8">
            {t('footer.address')}
          </div>
          <div className="flex flex-row">
            <IoLocationOutline color={colors.theme} size={27} />
            <span className="ml-2">
              Av. das Américas, 8585 - Barra da Tijuca Rio de Janeiro - RJ, 22793-081 <br />
              Ref: Techset Academy - fundo do estacionamento
            </span>
          </div>
        </div>
      </div>
      <Cookies />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
